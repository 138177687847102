import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../assets/sort.png'
import _imports_1 from '../assets/reset.png'
import _imports_2 from '../assets/filter.png'
import _imports_3 from '../assets/search.png'
import _imports_4 from '../assets/close.png'
import _imports_5 from '../assets/fullscreen.png'
import _imports_6 from '../assets/copy.png'
import _imports_7 from '../assets/previous.png'
import _imports_8 from '../assets/next.png'


const _hoisted_1 = { class: "gallery-header-content collapsible-content active" }
const _hoisted_2 = { class: "flex-container flex-row" }
const _hoisted_3 = { class: "flex-static sort-wrapper" }
const _hoisted_4 = {
  id: "sortPopup",
  class: "flex-container flex-column sort-popup"
}
const _hoisted_5 = { class: "flex-static filter-wrapper" }
const _hoisted_6 = {
  id: "filterPopup",
  class: "flex-container flex-column filter-popup"
}
const _hoisted_7 = { class: "flex-static search-wrapper" }
const _hoisted_8 = {
  id: "searchPopup",
  class: "search-popup flex-container flex-row"
}
const _hoisted_9 = {
  id: "gallery",
  class: "flex-dynamic flex-container flex-row flex-wrap flex-sm-gap flex-justify-center page-container"
}
const _hoisted_10 = ["onload", "alt", "src"]
const _hoisted_11 = {
  id: "dialog",
  class: "photo-dialog"
}
const _hoisted_12 = { class: "toast" }
const _hoisted_13 = {
  id: "currentPhotoWrapper",
  class: "current-photo-wrapper"
}
const _hoisted_14 = ["alt", "src"]
const _hoisted_15 = {
  id: "details",
  class: "flex-container flex-column details-panel"
}
const _hoisted_16 = { class: "flex-static flex-container flex-column" }
const _hoisted_17 = { class: "flex-static photo-title" }
const _hoisted_18 = { class: "flex-static" }
const _hoisted_19 = { class: "flex-static text-no-wrap" }
const _hoisted_20 = { class: "flex-static text-no-wrap" }
const _hoisted_21 = { class: "flex-static text-no-wrap" }
const _hoisted_22 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", {
      id: "galleryHeader",
      class: "flex-static collapsible-header active",
      onClick: _cache[0] || (_cache[0] = (event) => _ctx.onClickCollapse(event))
    }, "Gallery"),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[28] || (_cache[28] = _createElementVNode("div", { class: "flex-dynamic" }, [
          _createTextVNode(" All photos are the property of Paul Sanderson. Most photos are available for purchase in higher quality. If you prefer to browse the store directly, you may do so "),
          _createElementVNode("a", {
            target: "_blank",
            href: "https://2-paul-sanderson.pixels.com/"
          }, "here")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            id: "sortButton",
            class: "sort-button",
            title: "Sort",
            alt: "Sort",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickSort && _ctx.onClickSort(...args))),
            src: _imports_0
          }),
          _withDirectives(_createElementVNode("img", {
            id: "resetSortButton",
            class: "reset-sort-button",
            title: "Reset Sort",
            alt: "Reset Sort",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickResetSort && _ctx.onClickResetSort(...args))),
            src: _imports_1
          }, null, 512), [
            [_vShow, _ctx.hasSetSort]
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "flex-static sort-item selected",
              onClick: _cache[3] || (_cache[3] = (event) => _ctx.onSortSelected(event.target as HTMLDivElement))
            }, "Newest First"),
            _createElementVNode("div", {
              class: "flex-static sort-item",
              onClick: _cache[4] || (_cache[4] = (event) => _ctx.onSortSelected(event.target as HTMLDivElement))
            }, "Oldest First")
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            id: "filterButton",
            class: "filter-button",
            title: "Filter",
            alt: "Filter",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onClickFilter && _ctx.onClickFilter(...args))),
            src: _imports_2
          }),
          _withDirectives(_createElementVNode("img", {
            id: "resetFilterButton",
            class: "reset-filter-button",
            title: "Reset Filter",
            alt: "Reset Filter",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onClickResetFilter && _ctx.onClickResetFilter(...args))),
            src: _imports_1
          }, null, 512), [
            [_vShow, _ctx.hasSetFilter]
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "flex-static filter-item selected",
              onClick: _cache[7] || (_cache[7] = (event) => _ctx.onFilterSelected(event.target as HTMLDivElement))
            }, "All"),
            _createElementVNode("div", {
              class: "flex-static filter-item selected",
              onClick: _cache[8] || (_cache[8] = (event) => _ctx.onFilterSelected(event.target as HTMLDivElement))
            }, "Wildlife"),
            _createElementVNode("div", {
              class: "flex-static filter-item selected",
              onClick: _cache[9] || (_cache[9] = (event) => _ctx.onFilterSelected(event.target as HTMLDivElement))
            }, "Landscape"),
            _createElementVNode("div", {
              class: "flex-static filter-item selected",
              onClick: _cache[10] || (_cache[10] = (event) => _ctx.onFilterSelected(event.target as HTMLDivElement))
            }, "Portrait"),
            _createElementVNode("div", {
              class: "flex-static filter-item selected",
              onClick: _cache[11] || (_cache[11] = (event) => _ctx.onFilterSelected(event.target as HTMLDivElement))
            }, "Street"),
            _createElementVNode("div", {
              class: "flex-static filter-item selected",
              onClick: _cache[12] || (_cache[12] = (event) => _ctx.onFilterSelected(event.target as HTMLDivElement))
            }, "Astro"),
            _createElementVNode("div", {
              class: "flex-static filter-item selected",
              onClick: _cache[13] || (_cache[13] = (event) => _ctx.onFilterSelected(event.target as HTMLDivElement))
            }, "Macro")
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            id: "searchButton",
            class: "search-button",
            title: "Search",
            alt: "Search",
            onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.onClickSearch && _ctx.onClickSearch(...args))),
            src: _imports_3
          }),
          _withDirectives(_createElementVNode("img", {
            id: "resetSearchButton",
            class: "reset-search-button",
            title: "Reset Search",
            alt: "Reset Search",
            onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.onClickResetSearch && _ctx.onClickResetSearch(...args))),
            src: _imports_1
          }, null, 512), [
            [_vShow, _ctx.hasSetSearch]
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              id: "searchField",
              autocomplete: "off",
              class: "search-field flex-static",
              title: "Search photos",
              type: "search",
              placeholder: "Search...",
              name: "term",
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.searchTerm) = $event)),
              onInput: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.onSearchFieldChanged && _ctx.onSearchFieldChanged(...args)))
            }, null, 544), [
              [_vModelText, _ctx.searchTerm]
            ]),
            _createElementVNode("select", {
              id: "searchType",
              class: "search-select flex-static",
              onInput: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.onSearchChanged && _ctx.onSearchChanged(...args)))
            }, _cache[27] || (_cache[27] = [
              _createElementVNode("option", null, "Type...", -1),
              _createElementVNode("option", null, "Name", -1),
              _createElementVNode("option", null, "Description", -1),
              _createElementVNode("option", null, "Title", -1),
              _createElementVNode("option", null, "Date", -1),
              _createElementVNode("option", null, "Exposure", -1),
              _createElementVNode("option", null, "Aperture", -1),
              _createElementVNode("option", null, "Focal Length", -1),
              _createElementVNode("option", null, "ISO", -1)
            ]), 32)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photos, (photo) => {
        return _withDirectives((_openBlock(), _createElementBlock("img", {
          key: photo.smallUrl,
          class: "flex-dynamic photo-tile",
          onload: (event) => event.target.style.opacity = '1',
          loading: "lazy",
          tabindex: "0",
          alt: photo.name,
          src: photo.smallUrl,
          onClick: _cache[19] || (_cache[19] = (event) => _ctx.onClickPhoto(event)),
          onKeyup: _cache[20] || (_cache[20] = _withKeys((event) => _ctx.onClickPhoto(event), ["enter"]))
        }, null, 40, _hoisted_10)), [
          [_vShow, photo.matchesSearch && photo.matchesFilter]
        ])
      }), 128))
    ]),
    _createElementVNode("dialog", _hoisted_11, [
      _createElementVNode("img", {
        class: "button-base close-button",
        title: "Close",
        alt: "Close",
        onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.onClickCloseDialog && _ctx.onClickCloseDialog(...args))),
        src: _imports_4
      }),
      _createElementVNode("img", {
        class: "button-base fullscreen-button",
        title: "Fullscreen",
        alt: "Fullscreen",
        onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.onClickFullscreen && _ctx.onClickFullscreen(...args))),
        src: _imports_5
      }),
      _createElementVNode("img", {
        class: "button-base copy-button",
        title: "Copy Link",
        alt: "Copy Link",
        onClick: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.onClickCopyLink && _ctx.onClickCopyLink(...args))),
        src: _imports_6
      }),
      _withDirectives(_createElementVNode("div", _hoisted_12, "Copied Link!", 512), [
        [_vShow, _ctx.showToast]
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("img", {
          class: "button-base fullscreen-close-button",
          title: "Close",
          alt: "Close",
          onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.onClickCloseFullscreen && _ctx.onClickCloseFullscreen(...args))),
          src: _imports_4
        }),
        _cache[29] || (_cache[29] = _createElementVNode("div", {
          id: "previousPhotoWrapper",
          class: "previous-photo-wrapper"
        }, [
          _createElementVNode("img", {
            id: "previousPhoto",
            class: "previous-photo"
          })
        ], -1)),
        _withDirectives(_createElementVNode("img", {
          id: "previousButton",
          class: "button-base previous-button",
          title: "Previous",
          alt: "Previous",
          onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.onClickPrevious && _ctx.onClickPrevious(...args))),
          src: _imports_7
        }, null, 512), [
          [_vShow, _ctx.currentIndex > 0 && _ctx.showButtons]
        ]),
        _createElementVNode("img", {
          id: "currentPhoto",
          class: "current-photo",
          alt: _ctx.currentPhoto.name,
          src: _ctx.currentPhoto.largeUrl
        }, null, 8, _hoisted_14),
        _withDirectives(_createElementVNode("img", {
          id: "nextButton",
          class: "button-base next-button",
          title: "Next",
          alt: "Next",
          onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.onClickNext && _ctx.onClickNext(...args))),
          src: _imports_8
        }, null, 512), [
          [_vShow, _ctx.currentIndex < _ctx.photos.length - 1 && _ctx.showButtons]
        ]),
        _cache[30] || (_cache[30] = _createElementVNode("div", {
          id: "nextPhotoWrapper",
          class: "next-photo-wrapper"
        }, [
          _createElementVNode("img", {
            id: "nextPhoto",
            class: "next-photo"
          })
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[33] || (_cache[33] = _createElementVNode("h4", { class: "flex-static" }, "Details", -1)),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("h4", _hoisted_17, _toDisplayString(_ctx.formatTitle(_ctx.currentPhoto.title)), 1),
          _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.currentPhoto.description), 1),
          _createElementVNode("div", _hoisted_19, [
            _cache[31] || (_cache[31] = _createElementVNode("u", null, "Date", -1)),
            _createTextVNode(": " + _toDisplayString(_ctx.currentPhoto.date), 1)
          ]),
          _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.currentPhoto.exposure + ' • ' +
        _ctx.currentPhoto.aperture + ' • ' +
        _ctx.currentPhoto.focallength + ' • ' +
        _ctx.currentPhoto.iso), 1),
          _withDirectives(_createElementVNode("div", _hoisted_21, [
            _createElementVNode("a", {
              class: "shop-link",
              target: "_blank",
              href: 'https://fineartamerica.com/featured/' + _ctx.currentPhoto.title + '-paul-sanderson.html'
            }, "Shop Products", 8, _hoisted_22)
          ], 512), [
            [_vShow, _ctx.currentPhoto.title]
          ]),
          _cache[32] || (_cache[32] = _createElementVNode("div", null, [
            _createElementVNode("a")
          ], -1))
        ])
      ])
    ])
  ], 64))
}