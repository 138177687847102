import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-dynamic flex-container flex-column page-container" }
const _hoisted_2 = { class: "flex-container flex-row" }
const _hoisted_3 = { class: "flex-dynamic" }
const _hoisted_4 = { class: "collapsible-content active" }
const _hoisted_5 = { class: "flex-container flex-row flex-gap flex-justify-center" }
const _hoisted_6 = {
  class: "flex-dynamic align-left",
  style: {"max-width":"400px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[15] || (_cache[15] = _createElementVNode("h1", { class: "flex-static" }, "Paul Sanderson", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", {
            class: "collapsible-header active",
            onClick: _cache[0] || (_cache[0] = (event) => _ctx.onClickCollapse(event))
          }, "About"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[13] || (_cache[13] = _createElementVNode("img", {
                class: "flex-dynamic large-image",
                width: "266",
                height: "400",
                title: "Paul Sanderson",
                alt: "Paul Sanderson",
                src: "/paulsanderson.webp"
              }, null, -1)),
              _createElementVNode("div", _hoisted_6, [
                _cache[4] || (_cache[4] = _createTextVNode(" This is the personal page for Paul Sanderson. He is a software developer in Bozeman, Montana, USA. Paul enjoys photography, playing guitar, the outdoors, a game of chess, and a variety of studies like history, economics, and linguistics.")),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _cache[6] || (_cache[6] = _createTextVNode(" The ")),
                _createVNode(_component_router_link, { to: "/gallery" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Gallery")
                  ])),
                  _: 1
                }),
                _cache[7] || (_cache[7] = _createTextVNode(" has a selection of Paul's photography - viewable as tiles, in a dialog, or fullscreen.")),
                _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                _cache[9] || (_cache[9] = _createTextVNode(" The ")),
                _createVNode(_component_router_link, { to: "/blog" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Blog")
                  ])),
                  _: 1
                }),
                _cache[10] || (_cache[10] = _createTextVNode(" has details on which features have been added and which are yet to come.")),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                _cache[12] || (_cache[12] = _createTextVNode(" The rest of the site is under construction. It is being built from scratch and is intended to always be a work in progress. Feedback is welcome. "))
              ])
            ])
          ]),
          _createElementVNode("h2", {
            class: "collapsible-header",
            onClick: _cache[1] || (_cache[1] = (event) => _ctx.onClickCollapse(event))
          }, "Contact"),
          _cache[14] || (_cache[14] = _createElementVNode("p", { class: "collapsible-content" }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum dignissim elementum. Nunc rhoncus leo vel vehicula efficitur. Morbi venenatis orci nec euismod aliquam. Nullam lobortis nulla at justo blandit pellentesque. Suspendisse feugiat tempor nisl, nec interdum lorem blandit ut. Donec ultrices tincidunt consectetur. Aenean tellus massa, lacinia ut erat ac, ultricies finibus felis. Donec suscipit lectus eu tortor blandit, at faucibus eros dapibus.", -1))
        ])
      ])
    ])
  ], 64))
}